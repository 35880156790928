<template>
    <header> 
        <Drawer @close="toggle" align="right" :closeable="true">
            <div v-if="open">
                <ul>
                <li @click="toggle">
                    <router-link to="/howto">comment participer ?</router-link>
                </li>
                <li @click="toggle">
                    <router-link to="/form">jouez</router-link>
                </li>
                <li @click="toggle">
                    <router-link to="/dotations">découvrir les lots</router-link>
                </li>
                </ul>
            </div>
            </Drawer>

        <img class="accordion" @click="toggle"  src="../assets/images/accordion.png">
        <div class="newlogo"><router-link to="/"><img  src="../assets/images/logo.png"></router-link></div>
        <ul class="nav-header desktop">
            <router-link to="/"><img class="logo" src="../assets/images/logo.png"></router-link>
            <li><router-link to="/howto">COMMENT PARTICIPER ?</router-link></li>
            <li><router-link to="/form" class="jouez">JOUEZ</router-link></li>
            <li><router-link to="/dotations">découvrir les lots</router-link></li>    
            <li class="last-child">&nbsp;&nbsp;</li>       
        </ul>

    </header>
</template>
<script>
import Drawer from "vue-simple-drawer"

export default {
  name: 'HeaderElem',
  data() {
    return {
      open: false
    }
  },
  components: {
    Drawer
  },
  methods: {
    toggle() {
      this.open= !this.open
    }
  }
}
</script>
<style lang="scss"scoped>$mobile-width : 900px;

header {
    background-image: url('../assets/images/fond-header.jpg');
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: 100% 100%;
}

.accordion {
    position: absolute;
    right: 2.9%;
    z-index: 4;
    top: 3%;
    width: 17%;
    z-index:20;

    @media screen and (min-width: $mobile-width) {
        display: none;
    }
}

.last-child {
    width: 40px;
}

.top-mobile {
    width: 100%;
}

.newlogo {
    width: 1500px;
    position: absolute;
    text-align: left;
    margin: 0 auto;
    top: 125px;
    z-index: 19;
    left: 50%;
    transform: translate(-50%, -50%);

    @media screen and (max-width: 1510px) {
        width: 98%;
    }

    @media screen and (max-width: 1440px) {
        top: 105px;
        width: 1200px;
    }

    @media screen and (max-width: 1210px) {
        width: 98%;
    }

    @media screen and (max-width: 1061px) {
        top: 95px;
    }

    @media screen and (max-width: 900px) {
        display: none;
    }

    img {
        width: 230px;

        @media screen and (max-width: 1440px) {
            width: 195px;
        }

        @media screen and (max-width: 1061px) {
            width: 180px;
        }
    }
}


.logo {
    position: absolute;
    width: 230px;
    left: 195px;
    top: 2px;
    z-index: 3;

    @media screen and (max-width: 1820px) {
        left: 125px;
    }

    @media screen and (max-width: 1720px) {
        left: 95px;
    }

    @media screen and (max-width: 1650px) {
        left: 65px;

        width: 210px;
    }

    @media screen and (max-width: 1440px) {
        width: 185px;
        left: 110px;
    }


    @media screen and (max-width: 1338px) {
        left: 55px;
    }

    @media screen and (max-width: 1168px) {
        left: 15px;
    }

    display:none;

}

.nav-header {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style-type: none;
    max-width: 1920px;
    margin: 0 auto;
    padding: 0;
    height: 55px;
    width: 100%;
    font-family: 'Sound Bubble DEMO';
    position: relative;
  


    @media screen and (min-width: 1920px) {
        position: relative !important;
        margin: 0 auto;
    }

    @media screen and (max-width: 1000px) {
        width: fit-content;
    }

    li {
        text-transform: uppercase;
        margin-left: 80px;
        position:relative;
        z-index:65;

        @media screen and (max-width: 1100px) {
            margin-left: 25px;
        }
    }

    li a {
        text-decoration: none;
        color: white;
        font-size: 30px;

        @media screen and (max-width: 1920px) {
            font-size: 25px;
        }
    }

    .jouez {
        color: yellow;
        font-size: 35px;
    }
}

</style>